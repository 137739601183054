import React from 'react'
import MediaQuery from 'react-responsive'
import { navigate } from 'gatsby'
import sampleApp from '../../images/home/appSample.webp'
import whyAppoint1 from '../../images/home/whyAappoint1.png'
import whyAppoint2 from '../../images/home/whyAappoint2.png'
import whyAppoint3 from '../../images/home/whyAappoint3.png'
import whyAppoint4 from '../../images/home/whyAappoint4.png'
import whyAppoint5 from '../../images/home/whyAappoint5.png'
import whyAppoint6 from '../../images/home/whyAappoint6.png'
import whyNow1TH from '../../images/home/whyNow1TH.png'
import whyNow2TH from '../../images/home/whyNow2TH.png'
import whyNow3TH from '../../images/home/whyNow3TH.png'
import whyNow1EN from '../../images/home/whyNow1EN.png'
import whyNow2EN from '../../images/home/whyNow2EN.png'
import whyNow3EN from '../../images/home/whyNow3EN.png'
import checkBox from '../../images/home/checkBox.png'
import createShop from '../../images/home/createShop.webp'

export default function home(props) {
  return (
    <div className="fade-in" lang={`${props.lang}`}>
      <MediaQuery minWidth={1024}>
        <div className="container mx-auto">
          <div className="2xl:h-32 xl:h-32 lg:h-16"></div>
          <div className="grid grid-cols-12 items-center">
            {/* Row 1 */}
            <div className="col-span-1"></div>
            <div className="col-span-5">
            <img
                alt="sampleApp"
                className=" float-left 
                            max:w-87p 2xl:w-87p xl:w-87p lg:w-87p
                            max:-mt-25p 2xl:-mt-25p xl:-mt-25p lg:-mt-25p
                            lg:-mb-47p"
                src={sampleApp}
              />
            </div>
            <div className="col-span-6">
              <div
                className="
                            text-body-header text-body-head-36-size font-bold
                            2xl:text-body-head-70-size 2xl:mt-16 2xl:mb-9
                            xl:text-body-head-60-size xl:mt-12 xl:mb-8
                            lg:text-body-head-45-size lg:mt-8 lg:mb-7
                        "
              >
                {/* {props.locale[`${props.lang}`].APP_DESCRIPTION_L1} */}
                <div lang="EN" className="inline">
                  {' '}
                  AAppoint
                </div>
              </div>
              <div
                className="
                            text-body-header text-body-head-36-size font-bold mt-2
                            2xl:text-body-head-36-size 2xl:mb-5
                            xl:text-size-31 xl:mb-4
                            lg:text-size-24 lg:mb-3
                        "
              >
                {props.locale[`${props.lang}`].APP_DESCRIPTION_L1}
              </div>
              <div
                className="
                            text-body-header text-body-head-36-size font-bold mt-2 mb-5
                            2xl:text-body-head-36-size
                            xl:xl:text-size-31
                            lg:text-size-24
                            leading-12
                        "
              >
                {props.locale[`${props.lang}`].APP_DESCRIPTION_L2}
              </div>
            </div>
            {/* Row 1 */}
            {/* Row 2 */}
            <div className="col-span-12"></div>
            <div className="col-span-6"></div>
            <div className="col-span-4 z-20">
              <div>
                <button
                  className="bg-menu-button rounded-full w-50p text-white hover:bg-menu-button-hover cursor-pointer
                                max:h-12 2xl:h-12 xl:h-10 lg:h-9
                                max:text-size-25 2xl:text-size-23 xl:text-size-21 lg:text-size-16"
                  onClick={() => navigate('/request-demo')}
                >
                  {props.locale[`${props.lang}`].TRY_FREE}
                </button>
                <br />
                <div
                  className="w-44p text-body-content-gray text-center
                                max:text-size-16 2xl:text-size-16 xl:text-size-14 lg:text-size-12
                            "
                >
                  {props.locale[`${props.lang}`].NO_CREDIT_REQUIRE}
                </div>
              </div>
            </div>
            {/* <div className="container">
              <img
                alt="sampleApp"
                className=" float-left 
                            max:ml-5p 2xl:ml-1.5p xl:ml-1.5p lg:ml-1.5p
                            max:w-45p 2xl:w-45p xl:w-45p lg:w-45p
                            max:mt-10p 2xl:mt-7p xl:mt-7p lg:mt-7p"
                src={sampleApp}
              />
            </div> */}
            {/* Row 2 */}
          </div>
          <div className="max:h-40 2xl:h-28 xl:h-20 lg:h-12"></div>
          <img
                className="container-1710 mx-auto mt-9"
                alt="createShop"
                src={createShop}
                width="80%"
              />
        </div>
        <div className="max:h-32 2xl:h-24 xl:h-16 lg:h-12"></div>
        {props.lang == 'TH' ? (
          <div
            className="container-1710 mx-auto grid grid-cols-12 bg-content-container
                        max:py-20 2xl:py-20 xl:py-16 lg:py-12
                        max:h-31vw
        "
          >
            <div
              className="
                        col-span-7 m-auto text-center px-12
                "
            >
              <div
                className="
                        text-body-header font-bold
                        max:text-size-55 2xl:text-size-50 xl:text-size-40 lg:text-size-35
                        max:-mt-5p 2xl:-mt-5p xl:-mt-5p lg:-mt-5p
                    "
              >
                {props.locale[`${props.lang}`].AAPPOINT_MARKET_L1}
                <div lang="EN" className="inline">
                  {' '}
                  AAppoint
                </div>
              </div>
              {/* Video section */}
              <div className="w-33p h-18vw">
                <iframe
                  title="get to know aappoint"
                  className="mx-auto"
                  width="300%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen="allowFullScreen"
                  src="https://www.youtube.com/embed/_6KHMQeVz-k"
                ></iframe>
              </div>
              {/* Video section */}
              <div
                className="
                        text-body-content-green
                        max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20
                        mt-1p ml-12p
                        absolute cursor-pointer
                    "
                onClick={() =>
                  window.open(
                    'https://www.youtube.com/channel/UCIbSLJ7qHs71QEIJkjwRiEA'
                  )
                }
              >
                {props.locale[`${props.lang}`].SEE_MORE}
              </div>
            </div>
            <div
              className="
                        col-span-5 m-auto
                "
            >
              <img
                className="mx-auto inline -mt-8"
                alt="checkBox"
                src={checkBox}
                width="11%"
              />
              <div
                className="
                          text-body-content-green font-bold inline
                            max:text-size-35 max:leading-16
                            2xl:text-size-32 2xl:leading-16
                            xl:text-size-27 xl:leading-16
                            lg:text-size-21 lg:leading-12
                        "
              >
                {props.locale[`${props.lang}`].MARKET_DETAIL_L1}
              </div>
              <br />
              <br />
              <img
                className="mx-auto inline -mt-8"
                alt="checkBox"
                src={checkBox}
                width="11%"
              />
              <div
                className="
                          text-body-content-green font-bold inline
                          max:text-size-35 max:leading-16
                          2xl:text-size-32 2xl:leading-16
                          xl:text-size-27 xl:leading-16
                          lg:text-size-21 lg:leading-12
                        "
              >
                {props.locale[`${props.lang}`].MARKET_DETAIL_L2}
              </div>
              <br />
              <br />
              <img
                className="mx-auto inline -mt-8"
                alt="checkBox"
                src={checkBox}
                width="11%"
              />
              <div
                className="
                          text-body-content-green font-bold inline
                          max:text-size-35 max:leading-16
                          2xl:text-size-32 2xl:leading-16
                          xl:text-size-27 xl:leading-16
                          lg:text-size-21 lg:leading-12
                        "
              >
                {props.locale[`${props.lang}`].MARKET_DETAIL_L3}
              </div>
              <div
                className="
                          text-body-content-green font-bold
                          max:text-size-35 max:leading-16
                          2xl:text-size-32 2xl:leading-16
                          xl:text-size-27 xl:leading-16
                          lg:text-size-21 lg:leading-12
                          ml-11p
                        "
              >
                {props.locale[`${props.lang}`].MARKET_DETAIL_L4}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="container-1710 mx-auto grid grid-cols-12 bg-content-container
                        max:py-20 2xl:py-20 xl:py-16 lg:py-12
        "
          >
            {/* <div className="col-span-6 mx-auto">
            {window.matchMedia('(max-width: 1024px)').matches ? (
              <iframe
                title="aappoint"
                width="450"
                height="253.16‬"
                frameBorder="0"
                src="https://www.youtube.com/embed/T36WKNvUuEo"
              ></iframe>
            ) : (
              <iframe
                title="aappoint"
                width="563"
                height="316.69"
                frameBorder="0"
                src="https://www.youtube.com/embed/T36WKNvUuEo"
              ></iframe>
            )}
          </div> */}

            <div
              className="
                        col-span-6 m-auto text-center px-12
                "
            >
              <div
                lang="EN"
                className="
                        text-body-header font-bold
                        max:text-size-55 max:leading-17
                        2xl:text-size-50 2xl:leading-16
                        xl:text-size-40 xl:leading-12
                        lg:text-size-32 lg:leading-12
                    "
              >
                {props.locale[`${props.lang}`].AAPPOINT_MARKET_L1}
              </div>
              {/* Video section */}
              <div className="w-33p h-18vw">
                <iframe
                  title="get to know aappoint"
                  className="mx-auto"
                  width="300%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen="allowFullScreen"
                  src="https://www.youtube.com/embed/_6KHMQeVz-k"
                ></iframe>
              </div>
              {/* Video section */}
              <div
                className="
                        text-body-content-green
                        max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20
                        mt-1p ml-12p
                        absolute cursor-pointer
                    "
                onClick={() =>
                  window.open(
                    'https://www.youtube.com/channel/UCIbSLJ7qHs71QEIJkjwRiEA'
                  )
                }
              >
                {props.locale[`${props.lang}`].SEE_MORE}
              </div>
            </div>
            <div
              className="
                        col-span-6 m-auto
                "
            >
              <img
                className="mx-auto inline -mt-5"
                alt="checkBox"
                src={checkBox}
                width="8%"
              />
              <div
                className="
                          text-body-content-green font-bold inline
                            2xl:text-size-33 2xl:leading-16
                            xl:text-size-24 xl:leading-16
                            lg:text-size-19 lg:leading-12
                        "
              >
                {props.locale[`${props.lang}`].MARKET_DETAIL_L1}
              </div>
              <br />
              <br />
              <img
                className="mx-auto inline -mt-5"
                alt="checkBox"
                src={checkBox}
                width="8%"
              />
              <div
                lang="EN"
                className="
                          text-body-content-green font-bold inline
                          2xl:text-size-33 2xl:leading-16
                          xl:text-size-24 xl:leading-16
                          lg:text-size-19 lg:leading-12
                        "
              >
                {props.locale[`${props.lang}`].MARKET_DETAIL_L2}
              </div>
              <br />
              <br />
              <img
                className="mx-auto inline -mt-5"
                alt="checkBox"
                src={checkBox}
                width="8%"
              />
              <div
                className="
                          text-body-content-green font-bold inline
                            2xl:text-size-33 2xl:leading-16
                            xl:text-size-24 xl:leading-16
                            lg:text-size-19 lg:leading-12
                        "
              >
                {props.locale[`${props.lang}`].MARKET_DETAIL_L3}
              </div>
            </div>
          </div>
        )}
        {/* Video section */}
        {/* Why aappoint section */}
        <div className="2xl:h-32 xl:h-32 lg:h-20"></div>
        <div
          className="
                text-body-header font-bold text-center
                2xl:text-body-head-36-size
                xl:text-body-head-36-size
                lg:text-body-head-28-size
            "
        >
          {props.locale[`${props.lang}`].WHY_AAPPOINT}
        </div>
        <div className="h-20"></div>
        {/* Why aappoint pix Section */}
        <div className="container-1420 mx-auto grid grid-cols-12 text-center items-end">
          <div className="col-span-4">
            <img
              className="mx-auto"
              alt="whyAppoint1"
              src={whyAppoint1}
              width="69.7%"
            />
            <div className="h-4"></div>
          </div>
          <div className="col-span-4">
            <img
              className="mx-auto"
              alt="whyAppoint2"
              src={whyAppoint2}
              width="67.4%"
            />
            <div className="h-4"></div>
          </div>
          <div className="col-span-4">
            <img
              className="mx-auto"
              alt="whyAppoint3"
              src={whyAppoint3}
              width="84.5%"
            />
            <div className="h-4"></div>
          </div>
        </div>
        {/* Why aappoint pix Section */}
        {/* Why aappoint text Section */}
        <div className="container-1420 mx-auto grid grid-cols-12 text-center items-start">
          <div className="col-span-4">
            <div className="text-body-content-gray text-body-con-16-size w-72p mx-auto">
              {props.locale[`${props.lang}`].WHY_AAPPOINT1}
            </div>
          </div>
          <div className="col-span-4">
            <div className="text-body-content-gray text-body-con-16-size w-72p mx-auto">
              {props.locale[`${props.lang}`].WHY_AAPPOINT2}
            </div>
          </div>
          <div className="col-span-4">
            <div className="text-body-content-gray text-body-con-16-size w-72p mx-auto">
              {props.locale[`${props.lang}`].WHY_AAPPOINT3}
            </div>
          </div>
        </div>
        {/* Why aappoint text Section */}
        {/* Why aappoint pix2 Section */}
        <div className="container-1420 mx-auto grid grid-cols-12 text-center items-end">
          <div className="col-span-12 h-24"></div>
          <div className="col-span-4">
            <img
              className="mx-auto"
              alt="whyAppoint4"
              src={whyAppoint4}
              width="51.5%"
            />
            <div className="h-4"></div>
          </div>
          <div className="col-span-4">
            <img
              className="mx-auto"
              alt="whyAppoint5"
              src={whyAppoint5}
              width="72.2%"
            />
            <div className="h-4"></div>
          </div>
          <div className="col-span-4">
            <img
              className="mx-auto"
              alt="whyAppoint6"
              src={whyAppoint6}
              width="71%"
            />
            <div className="h-4"></div>
          </div>
        </div>
        {/* Why aappoint pix2 Section */}
        {/* Why aappoint text2 Section */}
        <div className="container-1420 mx-auto grid grid-cols-12 text-center items-start">
          <div className="col-span-4">
            <div className="text-body-content-gray text-body-con-16-size w-72p mx-auto">
              {props.locale[`${props.lang}`].WHY_AAPPOINT4}
            </div>
          </div>
          <div className="col-span-4">
            <div className="text-body-content-gray text-body-con-16-size w-72p mx-auto">
              {props.locale[`${props.lang}`].WHY_AAPPOINT5}
            </div>
          </div>
          <div className="col-span-4">
            <div className="text-body-content-gray text-body-con-16-size w-72p mx-auto">
              {props.locale[`${props.lang}`].WHY_AAPPOINT6}
            </div>
          </div>
        </div>
        {/* Why aappoint text2 Section */}
        {/* Why aappoint section */}
        {/* Why now section */}
        <div className="2xl:h-28 xl:h-28 lg:h-20"></div>
        <div
          className="
                text-body-header font-bold text-center
                2xl:text-body-head-36-size
                xl:text-body-head-36-size
                lg:text-body-head-28-size
            "
        >
          {props.locale[`${props.lang}`].WHY_NOW}
        </div>
        <div className="h-20"></div>
        <div className="container-1420 mx-auto grid grid-cols-12">
          <div className="col-span-4 ml-auto border-4 border-content-green rounded-lg w-265.6 h-56">
            {props.lang === 'TH' ? (
              <img
                className="mx-auto mt-20"
                alt="whyNow1TH"
                src={whyNow1TH}
                width="56.8%"
              />
            ) : (
              <img
                className="mx-auto mt-20"
                alt="whyNow1EN"
                src={whyNow1EN}
                width="56.8%"
              />
            )}
          </div>
          <div className="col-span-4 mx-auto border-4 border-content-green rounded-lg w-265.6 h-56">
            <div>
              {props.lang === 'TH' ? (
                <img
                  className="mx-auto mt-20"
                  alt="whyNow2TH"
                  src={whyNow2TH}
                  width="66%"
                />
              ) : (
                <img
                  className="mx-auto mt-20"
                  alt="whyNow2EN"
                  src={whyNow2EN}
                  width="66%"
                />
              )}
            </div>
          </div>
          <div className="col-span-4 mr-auto border-4 border-content-green rounded-lg w-265.6 h-56">
            <div>
              {props.lang === 'TH' ? (
                <img
                  className="mx-auto mt-20"
                  alt="whyNow3TH"
                  src={whyNow3TH}
                  width="65%"
                />
              ) : (
                <img
                  className="mx-auto mt-20"
                  alt="whyNow3EN"
                  src={whyNow3EN}
                  width="65%"
                />
              )}
            </div>
          </div>
        </div>
        {/* Why now section */}
        {/* Start now section */}
        <div className="2xl:h-32 xl:h-32 lg:h-20"></div>
        <div
          className="
                text-body-header font-bold text-center
                2xl:text-body-head-36-size
                xl:text-body-head-36-size
                lg:text-body-head-28-size
            "
        >
          {props.locale[`${props.lang}`].GET_START}
        </div>
        <div className="h-10"></div>
        <div className="text-center">
          <div>
            <button
              className="bg-menu-button rounded-full w-15p text-white hover:bg-menu-button-hover cursor-pointer
                        max:h-12 2xl:h-12 xl:h-10 lg:h-9
                        max:text-size-25 2xl:text-size-25 xl:text-size-23 lg:text-size-20"
              onClick={() => navigate('/request-demo')}
            >
              {props.locale[`${props.lang}`].TRY_NOW}
            </button>
            <div className="h-2"></div>
            <div
              className="text-body-content-gray
                        max:text-size-16 2xl:text-size-16 xl:text-size-14 lg:text-size-12
                    "
            >
              {props.locale[`${props.lang}`].NO_CREDIT_REQUIRE}
            </div>
          </div>
        </div>
        {/* Start now section */}
      </MediaQuery>
      <MediaQuery maxWidth={1023}>
        <div className="container-mobile mx-auto">
          <div className="h-28 md:h-40 sm:h-32"></div>
          <div className="text-center">
            <div
              className="
                            text-body-header font-bold
                            text-size-20 md:text-size-35 sm:text-size-30
                            leading-8 md:leading-10 sm:leading-8
                        "
            >
              {props.lang == 'TH' ? (
                <div>
                  <div lang="EN" className="inline">
                    AAppoint
                  </div>{' '}
                  {props.locale[`${props.lang}`].APP_DESCRIPTION_L1}
                  <br />
                  {props.locale[`${props.lang}`].APP_DESCRIPTION_L2}
                </div>
              ) : (
                <div className="max-w-100 mx-auto">
                  {props.locale[`${props.lang}`].APP_DESCRIPTION_L1}{' '}
                </div>
              )}
            </div>
            <img
              alt="sampleAppMobile"
              className="mx-auto"
              src={sampleApp}
              width="80%"
            />
            <button
              className="bg-menu-button rounded-full w-44p text-white 
                            text-size-16 md:text-size-20 sm:text-size-18
                            h-10 md:h-14 sm:h-12"
              onClick={() => navigate('/request-demo')}
            >
              {props.locale[`${props.lang}`].TRY_FREE}
            </button>
            <br />
            <div className="text-body-content-gray text-size-13">
              {props.locale[`${props.lang}`].NO_CREDIT_REQUIRE}
            </div>
          </div>
        </div>
        <div className="h-9 md:h-9 sm:h-9"></div>
        <img
                className="mx-auto"
                alt="createShop"
                src={createShop}
                width="95%"
              />
        <div className="h-9 md:h-9 sm:h-9"></div>
        <div className="w-full bg-content-container text-center p-6">
          <div
            className="text-body-header font-bold mt-2 mb2
                            text-size-16 md:text-size-24 sm:text-size-22"
          >
            {props.locale[`${props.lang}`].AAPPOINT_MARKET_L1}
            <div lang="EN" className="inline">
              {' '}
              AAppoint
            </div>
          </div>
          {/* video mobile section */}
          <div className=" w-86p md:w-65p sm:w-65p h-40vw md:h-32.45vw sm:h-32.45vw mx-auto">
            <iframe
              title="get to know aappoint"
              className="mx-auto"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen="allowFullScreen"
              webkitallowfullscreen="webkitallowfullscreen"
              src="https://www.youtube.com/embed/_6KHMQeVz-k"
            ></iframe>
          </div>
          <div
            className="
                        text-body-content-green
                        text-size-16 md:text-size-20 sm:text-size-18 mt-2p
                    "
            onClick={() =>
              window.open(
                'https://www.youtube.com/channel/UCIbSLJ7qHs71QEIJkjwRiEA'
              )
            }
          >
            {' '}
            {props.locale[`${props.lang}`].SEE_MORE}{' '}
          </div>
          {/* video mobile section */}
          <div
            className="text-body-header font-bold mt-2 mb2
                            text-size-16 md:text-size-24 sm:text-size-22"
          >
            {props.locale[`${props.lang}`].AAPPOINT_MARKET_L2}
          </div>
          {/* Qulity service marketplace section */}
          {props.lang == 'TH' ? (
            <div
              className="text-body-content-green font-bold mt-5p mb-2 ml-13p
                           text-size-11 md:text-size-20 sm:text-size-18"
            >
              <br />
              <div
                className="text-left mx-auto
                                      w-90p md:w-75p sm:w-80p
                      "
              >
                <img
                  className="mx-auto inline -mt-4"
                  alt="checkBox"
                  src={checkBox}
                  width="10%"
                />
                <div className="inline">
                  {props.locale[`${props.lang}`].MARKET_DETAIL_L1}
                </div>
                <br />
                <br />
                <img
                  className="mx-auto inline -mt-4"
                  alt="checkBox"
                  src={checkBox}
                  width="10%"
                />
                <div className="inline">
                  {props.locale[`${props.lang}`].MARKET_DETAIL_L2}
                </div>
                <br />
                <br />
                <img
                  className="mx-auto inline -mt-4"
                  alt="checkBox"
                  src={checkBox}
                  width="10%"
                />
                <div className="inline">
                  {props.locale[`${props.lang}`].MARKET_DETAIL_L3}
                </div>
                <div className="ml-10p">
                  {props.locale[`${props.lang}`].MARKET_DETAIL_L4}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="text-body-content-green font-bold mt-2 mb-2
                              text-size-11 md:text-size-20 sm:text-size-18"
            >
              <br />
              <div
                className="text-left mx-auto
                              w-90p md:w-75p sm:w-80p
              "
              >
                <img
                  className="mx-auto inline -mt-4"
                  alt="checkBox"
                  src={checkBox}
                  width="10%"
                />
                <div className="inline">
                  {props.locale[`${props.lang}`].MARKET_DETAIL_L1}
                </div>
                <br />
                <br />
                <img
                  className="mx-auto inline -mt-4"
                  alt="checkBox"
                  src={checkBox}
                  width="10%"
                />
                <div lang="EN" className="inline">
                  {props.locale[`${props.lang}`].MARKET_DETAIL_L2}
                </div>
                <br />
                <br />
                <img
                  className="mx-auto inline -mt-4"
                  alt="checkBox"
                  src={checkBox}
                  width="10%"
                />
                <div className="inline">
                  {props.locale[`${props.lang}`].MARKET_DETAIL_L3}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* video mobile section */}
        {/* WHY Appoint Mobile Section */}
        <div className="h-11 md:h-16 sm:h-14"></div>
        <div
          className="
                        text-body-header font-bold text-center
                        text-size-18 md:text-size-24 sm:text-size-22
                "
        >
          {props.locale[`${props.lang}`].WHY_AAPPOINT}
        </div>
        <div className="h-11 md:h-16 sm:h-14"></div>
        {/* WHY Appoint Mobile 1 */}
        <img
          className="mx-auto"
          alt="whyAppointMobile1"
          src={whyAppoint1}
          width="69.7%"
        />
        <div className="h-4"></div>
        <div
          className="w-80p mx-auto text-body-content-gray text-center
                            text-size-15 md:text-size-18 sm:text-size-16
                "
        >
          {props.locale[`${props.lang}`].WHY_AAPPOINT1}
        </div>
        {/* WHY Appoint Mobile 1 */}
        <div className="h-11 md:h-16 sm:h-14"></div>
        {/* WHY Appoint Mobile 2 */}
        <img
          className="mx-auto"
          alt="whyAppointMobile2"
          src={whyAppoint2}
          width="67.4%"
        />
        <div className="h-4"></div>
        <div
          className="w-80p mx-auto text-body-content-gray text-center
                            text-size-15 md:text-size-18 sm:text-size-16
                "
        >
          {props.locale[`${props.lang}`].WHY_AAPPOINT2}
        </div>
        {/* WHY Appoint Mobile 2 */}
        <div className="h-11 md:h-16 sm:h-14"></div>
        {/* WHY Appoint Mobile 3 */}
        <img
          className="mx-auto"
          alt="whyAppointMobile3"
          src={whyAppoint3}
          width="68%"
        />
        <div className="h-4"></div>
        <div
          className="w-80p mx-auto text-body-content-gray text-center
                            text-size-15 md:text-size-18 sm:text-size-16
                "
        >
          {props.locale[`${props.lang}`].WHY_AAPPOINT3}
        </div>
        {/* WHY Appoint Mobile 3 */}
        <div className="h-11 md:h-16 sm:h-14"></div>
        {/* WHY Appoint Mobile 4 */}
        <img
          className="mx-auto"
          alt="whyAppointMobile4"
          src={whyAppoint4}
          width="51.5%"
        />
        <div className="h-4"></div>
        <div
          className="w-80p mx-auto text-body-content-gray text-center
                            text-size-15 md:text-size-18 sm:text-size-16
                "
        >
          {props.locale[`${props.lang}`].WHY_AAPPOINT4}
        </div>
        {/* WHY Appoint Mobile 4 */}
        <div className="h-11 md:h-16 sm:h-14"></div>
        {/* WHY Appoint Mobile 5 */}
        <img
          className="mx-auto"
          alt="whyAppointMobile5"
          src={whyAppoint5}
          width="72.2%"
        />
        <div className="h-4"></div>
        <div
          className="w-80p mx-auto text-body-content-gray text-center
                            text-size-15 md:text-size-18 sm:text-size-16
                "
        >
          {props.locale[`${props.lang}`].WHY_AAPPOINT5}
        </div>
        {/* WHY Appoint Mobile 5 */}
        <div className="h-11 md:h-16 sm:h-14"></div>
        {/* WHY Appoint Mobile 6 */}
        <img
          className="mx-auto"
          alt="whyAppointMobile6"
          src={whyAppoint6}
          width="71%"
        />
        <div className="h-4"></div>
        <div
          className="w-80p mx-auto text-body-content-gray text-center
                            text-size-15 md:text-size-18 sm:text-size-16
                "
        >
          {props.locale[`${props.lang}`].WHY_AAPPOINT6}
        </div>
        {/* WHY Appoint Mobile 6 */}
        {/* WHY Appoint Mobile Section */}
        <div className="h-24 md:h-32 sm:h-28"></div>
        {/* WHY Now Mobile section */}
        <div
          className="
                        text-body-header font-bold text-center
                        text-size-23 md:text-size-29 sm:text-size-25
                "
        >
          {props.locale[`${props.lang}`].WHY_AAPPOINT}
        </div>
        <div className="h-11 md:h-16 sm:h-14"></div>
        <div className="container-mobile mx-auto">
          <div className="grid grid-cols-3">
            <div
              className="border-4 border-content-green rounded-lg flex items-center ml-auto
                            w-28 md:w-48 sm:w-40
                            h-28 md:h-48 sm:h-40
                        "
            >
              {props.lang === 'TH' ? (
                <img
                  className="mx-auto w-86p"
                  alt="whyNow1THMobile"
                  src={whyNow1TH}
                />
              ) : (
                <img
                  className="mx-auto w-86p"
                  alt="whyNow1ENMobile"
                  src={whyNow1EN}
                />
              )}
            </div>
            <div
              className="border-4 border-content-green rounded-lg flex items-center mx-auto
                            w-28 md:w-48 sm:w-40
                            h-28 md:h-48 sm:h-40
                        "
            >
              {props.lang === 'TH' ? (
                <img
                  className="mx-auto w-full"
                  alt="whyNow2THMobile"
                  src={whyNow2TH}
                />
              ) : (
                <img
                  className="mx-auto w-full"
                  alt="whyNow2ENMobile"
                  src={whyNow2EN}
                />
              )}
            </div>
            <div
              className="border-4 border-content-green rounded-lg flex items-center mr-auto
                            w-28 md:w-48 sm:w-40
                            h-28 md:h-48 sm:h-40
                        "
            >
              {props.lang === 'TH' ? (
                <img
                  className="mx-auto w-full"
                  alt="whyNow3THMobile"
                  src={whyNow3TH}
                />
              ) : (
                <img
                  className="mx-auto w-full"
                  alt="whyNow3ENMobile"
                  src={whyNow3EN}
                />
              )}
            </div>
          </div>
        </div>
        {/* WHY Now Mobile section */}
        <div className="h-11 md:h-16 sm:h-14"></div>
        <div
          className="
                    text-body-header font-bold text-center
                    text-size-23 md:text-size-29 sm:text-size-25
                "
        >
          {props.locale[`${props.lang}`].GET_START}
        </div>
        <div className="h-6 md:h-8 sm:h-7"></div>
        <div className="text-center">
          <div>
            <button
              className="bg-menu-button rounded-full w-44p text-white 
                            text-size-16 md:text-size-20 sm:text-size-18
                            h-10 md:h-14 sm:h-12"
              onClick={() => navigate('/request-demo')}
            >
              {props.locale[`${props.lang}`].TRY_NOW}
            </button>
            <br />
            <div className="text-body-content-gray text-size-13">
              {props.locale[`${props.lang}`].NO_CREDIT_REQUIRE}
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}
